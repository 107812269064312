<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Alerts Report</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12 text-right">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="time" v-model="filter.start" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="time" v-model="filter.end" class="form-control date" placeholder="To"/>
                        </div>
                        <div class="form-group mr-1">
                            <select class="form-control" v-model="filter.type">
                                <option selected value="">---Any Type---</option>
                                <option v-for="item in types" :key="item.value" :value="item.value">{{ item.text }}</option>
                            </select>
                        </div>
                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="reports"
                        :allowExcelExport='true'
                        :allowPaging="true"
                        :allowSorting="true"
                        :allowFiltering="false"
                        :allowResizing='true'
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column field='dateFormatted' minWidth='8' width='50' headerText="Date"></e-column>
                            <e-column field='type' minWidth='8' width='50' headerText="Type"></e-column>
                            <e-column field='message' headerText='Message'  minWidth='8' width='100'></e-column>
                            <!--                        <e-column field='_id' minWidth='8' width='70'  headerText='Details' :template="alertTemplate"></e-column>-->
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert";
import Vue from "vue";
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Filter,
    Resize
} from "@syncfusion/ej2-vue-grids";


Vue.use(GridPlugin);
export default {
    name: 'AlertReport',

    components:{
    },
    data: function () {
        return {
            filter:{
                to: '',
                from: '',
                type: '',
                end:'',
                start:'',
            },
            types:[],
            reports:[],
            pageSettings: {pageSize: 100},
            searching: false,
            showExportButton: false,
            reportLoading: false
        }
    },
    created: function(){
        this.load();
        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Filter,
            Resize
        ]
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        actionHandler: function(args) {
            console.log(args);
        },
        load: async function () {
            //Load the campaign
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            const dateString = [year, month, day].join('-');
            this.filter.from = dateString;
            this.filter.to = dateString;

            const typeList = await axios.get(`${this.$root.serverUrl}/admin/dropdown/alert-types`,{});
            if(typeList && !typeList.data.error){
                console.log({ typeList })
                this.types = typeList.data.data;
            }

        },
        async search(){
            try
            {
                this.searching = true;
                this.showExportButton = false;
                this.reportLoading = true;
                this.reports = [];


                const request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.filter.from) request.filters.from = this.filter.from;
                if(this.filter.to) request.filters.to = this.filter.to;
                if(this.filter.end) request.filters.end = this.filter.end;
                if(this.filter.start) request.filters.start = this.filter.start;
                if(this.filter.type) request.filters.type = this.filter.type;

                const response = await axios.get(`${this.$root.serverUrl}/admin/reports/alert-logs`,{params:request});
                if(response.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${response.data.error.message}`, icon: 'error'})}
                else {
                    this.reports = await response.data.data;
                    this.reportLoading = false;
                    this.showExportButton = true;
                }
                this.searching = false;
            }catch (e) {
                console.log(e);
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${e.message}`, icon: 'error'})
            }
        },
        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.reports,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },
        getTime: function(dateTime){
            let d = new Date(dateTime);
            return d.toLocaleTimeString('en-US');
        }
    }
}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>

